import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import ValuesPage from '../../components/ValuesPage';

const Values = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <ValuesPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Values.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Values);

export const pageQuery = graphql`
    query ValuesQuery {
        headerImage: file(relativePath: { eq: "values/values-bg.jpeg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        valuesImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/values/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 608) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        values: allMdx(
            sort: { order: DESC, fields: [frontmatter___order] }
            filter: { fileRelativePath: { glob: "**/values/**" } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        text
                        image
                        theme
                        order
                        lang
                        link
                    }
                }
            }
        }
    }
`;
