import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';

import WithBackgroundImage from '../Image/WithBackgroundImage';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
`;

const InnerContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1.5rem 2rem;
    box-shadow: inset 0 -1px 0 0 #d4d4ce;
    flex: 1 0 auto;
`;

const Title = styled.h3`
    color: ${({ theme }) => theme.grayDarker};
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 3rem 0 0.5rem 0;

    @media (max-width: 600px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin: 1rem 0 0rem 0;
    }
`;

const ValueText = styled.p`
    margin: 1rem 0 2rem 0;

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`;

const BigValue = ({ value: { text, title }, image }) => (
    <Container>
        <WithBackgroundImage
            image={image}
            style={{ minHeight: 190, zIndex: 0 }}
        />
        <InnerContainer>
            <Title>{title}</Title>
            <ValueText>{text}</ValueText>
        </InnerContainer>
    </Container>
);

BigValue.propTypes = {
    value: PropTypes.shape({
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(BigValue);
