import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import Helmet from 'react-helmet';
import {
    FaBeer,
    FaBicycle,
    FaHandHoldingHeart,
    FaCogs,
    FaChargingStation,
    FaLaptopCode,
    FaUserFriends,
} from 'react-icons/fa';

import AppBar from './AppBar';
import CallToAction from './CallToAction';
import BigValue from './Values/BigValue';
import Footer from './Footer';
import MaxWidthContainer from './MaxWidthContainer';
import BaseHeaderImage from './HeaderImage';
import TopBar from './TopBar';

const THEMES = {
    suppliers: { value: 'suppliers', icon: FaChargingStation },
    mobility: { value: 'mobility', icon: FaBicycle },
    office: { value: 'office', icon: FaBeer },
    sharing: { value: 'sharing', icon: FaHandHoldingHeart },
    workers: { value: 'workers', icon: FaUserFriends },
    products: { value: 'products', icon: FaLaptopCode },
    management: { value: 'management', icon: FaCogs },
};

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const HeaderImage = styled(BaseHeaderImage)`
    > div:first-child {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.2) 100%
        );
    }
`;

const Intro = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8rem 0;

    @media (max-width: 600px) {
        margin: 4rem 0;
    }
`;
const Title = styled.h1`
    color: ${({ theme }) => theme.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 2rem;
    }
`;

const TagLine = styled.p`
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    max-width: 55%;
    text-align: center;
    margin: 0 0 2rem 0;

    & a {
        color: inherit;
    }

    @media (max-width: 600px) {
        max-width: 90%;
    }
`;

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid ${({ theme }) => theme.white};
    position: absolute;
    bottom: 0;
    left: 50%;
`;

const SubTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
    padding: 0 4em 1em;

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 2rem;
        padding: 0 1em;
    }
`;

const GlobalContainer = styled.div`
    background-color: ${({ theme }) => theme.gray};
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ThemeList = styled.ul`
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-bottom: 2em;
    list-style: none;
`;

const ThemeItem = styled.li`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    margin: 0;
    margin-bottom: 2em;
    list-style: none;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    cursor: ${props => (props.selected ? 'inherit' : 'pointer')};
    width: 10em;
    height: 10em;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.blue};
    opacity: ${props => (props.selected ? 1 : 0.6)};
    transition: opacity 0.3s ease-in-out;
    :hover {
        opacity: 1;
    }
    svg {
        margin-top: 1em;
        margin-bottom: 0.5em;
        width: 100%;
        height: 4em;
    }
    ${props =>
        props.selected &&
        `
    ::after {
        position: absolute;
        display: flex;
        height: 20px;
        width: 20px;
        margin-top: calc(10em - 4em - 20px);
        margin-left: 60px;
        content: ' ';
        background-color: ${props.theme.blue};
        transform: rotate(45deg);
    }
    `}
`;

const ValuesContainer = styled.ul`
    background-color: ${({ theme }) => theme.gray};
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
`;

const ValuesItem = styled.li`
    display: flex;
    padding: 0.5em;
    margin: 0;
    list-style: none;
    box-sizing: border-box;

    @media (min-width: 600px) {
        width: 50%;
    }
`;

const Values = ({ locale, values, headerImage, valuesImages, t }) => {
    const [currentTheme, setCurrentTheme] = useState(THEMES.suppliers.value);
    return (
        <Container>
            <Helmet title={t('seo.valuesTitle')}>
                <html lang={locale} />
                <meta name="description" content={t('seo.valuesDescription')} />
            </Helmet>
            <TopBar white role="presentation" />
            <header>
                <AppBar />
                <HeaderImage image={headerImage}>
                    <MaxWidthContainer>
                        <Intro>
                            <Title>
                                {t('values.preTitle')}
                                <br />
                                {t('values.title')}
                            </Title>
                            <TagLine
                                dangerouslySetInnerHTML={{
                                    __html: t('values.tagline'),
                                }}
                            />
                        </Intro>
                    </MaxWidthContainer>
                    <div>
                        <ArrowUp />
                    </div>
                </HeaderImage>
            </header>
            <div role="main">
                <MaxWidthContainer>
                    <SubTitle>{t('values.intro')}</SubTitle>
                    <GlobalContainer>
                        <ThemeList>
                            {Object.values(THEMES).map(theme => {
                                const Icon = theme.icon;
                                return (
                                    <ThemeItem
                                        key={theme.value}
                                        selected={theme.value === currentTheme}
                                        onClick={() =>
                                            setCurrentTheme(theme.value)
                                        }
                                    >
                                        <span>
                                            {t(`values.themes.${theme.value}`)}
                                        </span>
                                        <Icon />
                                    </ThemeItem>
                                );
                            })}
                        </ThemeList>
                        <ValuesContainer>
                            {values
                                .filter(value => value.theme === currentTheme)
                                .sort(
                                    (valueA, valueB) =>
                                        valueA.order - valueB.order,
                                )
                                .map(value => {
                                    const image = valuesImages.find(image =>
                                        image.absolutePath.includes(
                                            value.image,
                                        ),
                                    );
                                    return (
                                        <ValuesItem key={value.id}>
                                            <BigValue
                                                value={value}
                                                image={image}
                                            />
                                        </ValuesItem>
                                    );
                                })}
                        </ValuesContainer>
                    </GlobalContainer>
                </MaxWidthContainer>
                <CallToAction />
            </div>
            <Footer />
        </Container>
    );
};

Values.propTypes = {
    locale: PropTypes.string,
    values: PropTypes.array.isRequired,
    headerImage: PropTypes.object,
    valuesImages: PropTypes.array,
    t: PropTypes.func.isRequired,
};

export default compose(
    mapProps(({ data, locale }) => ({
        locale,
        values: data.values.edges
            .map(({ node }) => ({
                id: node.id,
                ...node.frontmatter,
            }))
            .filter(value => value.lang === locale),
        valuesImages: data.valuesImages.edges.map(({ node }) => node),
        headerImage: data.headerImage,
    })),
    translate(),
)(Values);
